import {ShiftWithDriverAndVehicleModel} from './shift.model';
import {DeviceInfo} from './device-info';

export class VehicleModel {
  id: number;
  created: Date;
  active: boolean;
  groupId: number;
  name: string;
  label: string;
  licensePlate: string;
  lmuId: string;
  mapColor: string = null;
  hasNoTablet: boolean;
  cameraConfiguration: CameraConfiguration;
  hardwareConfiguration: HardwareConfiguration;
  reportingType: string;
  properties?: { [key: string]: string };

  public static vehicleCompareFn = (a, b) => {
    const aTitle = !!a.label ? a.label : a.name;
    const bTitle = !!b.label ? b.label : b.name;
    return aTitle.localeCompare(bTitle);
  }
}

export class VehicleModelWithActiveShift extends VehicleModel {
  activeShift: ShiftWithDriverAndVehicleModel;
  deviceInfo?: DeviceInfo;
}

export class VehicleBasic {
  id: number;
  vehicleTypeId: number;
  vehicleTypeName: string;
  vehicleTypeTitle: string;
  name: string;
  label: string;
  licensePlate: string;
  shiftCount: number;
}

export class VehicleStats {
  id: number;
  created: Date;
  trackLength: number;
  hoursInService: number;
  shiftCount: number;
  gpsSources: number[];
}

export class HardwareConfiguration {
  avlModel: AvlModel;
  sensorInputs: SensorInput[];
  digitalSpreader: DigitalSpreader;

  static getValidInputs(sensorInputs: SensorInput[]): SensorInput[] {
    const filteredInputs = sensorInputs.filter(input => input.type !== SensorType.NONE);
    return filteredInputs
        .flatMap((sensorInput, index) => { // used as mapNotNull()
          if (sensorInput.type !== SensorType.PLOW2W || // 1-input hw
              (sensorInput.type === SensorType.PLOW2W && // 2-input hw with previous non-2-input hw
                  (index === 0 ||
                      (index >= 1 && filteredInputs[index - 1].type !== SensorType.PLOW2W ) ||
                      (index >= 1 && filteredInputs[index - 1].type === SensorType.PLOW2W && filteredInputs[index - 1].bitNumber !== sensorInput.bitNumber)
                  )
              )
          ) {
            return sensorInput;
          } else {
            return [];
          }
        });
  }
}

export enum AvlModel {
  CALAMP_2630 = 'CALAMP_2630',
  CALAMP_3641 = 'CALAMP_3641',
  CALAMP_5530 = 'CALAMP_5530',
  PLOWOPS_HUB = 'PLOWOPS_HUB',
  PLOWOPS_HUB_C = 'PLOWOPS_HUB_C',
  NONE = 'NONE',
}


export class SensorInput {
  type?: SensorType;
  label?: string;
  rate?: number;
  reversed?: boolean;
  bitNumber?: number;
  constructor(
      public readonly id: number,
      public readonly name: string,
  ) {
  }
}

export enum SensorType {
  PLOW = 'PLOW',
  PLOW2W = 'PLOW2W',
  GRANULAR_SPREADER = 'GRANULAR_SPREADER',
  LIQUID_SPREADER = 'LIQUID_SPREADER',
  DIGITAL_GRANULAR_SPREADER = 'DIGITAL_GRANULAR_SPREADER',
  DIGITAL_LIQUID_SPREADER = 'DIGITAL_LIQUID_SPREADER',
  MOWER = 'MOWER',
  SWEEPER = 'SWEEPER',
  NONE = 'NONE',
}

export class CameraConfiguration {
  rotation?: number;
  localRecording?: LocalRecording;
  streaming?: Streaming;

  constructor() {
    this.rotation = 0;
    this.localRecording = new LocalRecording();
    this.streaming = new Streaming();
  }
}

export class LocalRecording {
  captureEnabled: boolean;
  // interval of capturing individual images, 0 = disabled
  captureImageInterval: number; // minutes
}

export class Streaming {
  // how long the vide is, 0 = disabled
  // seconds (30), will enable button in UI to start capture on demand from Dashboard (default 30s)
  captureStreamDuration: number;
  // interval of capturing individual images, 0 = disabled
  captureImageInterval: number; // minutes
  // capture image on observation event
  observationCapture: boolean;
}


export class DigitalSpreader {
  model: DigitalSpreaderModel;
  settings: DigitalSpreaderSettings = new DigitalSpreaderSettings();
}

export class DigitalSpreaderSettings {
  readLiquid: boolean;
  readGranular: boolean;
  liquidMaxRate: number = null;
  granularMaxRate: number = null;
  granularBlastRate?: number = null;
}

export enum DigitalSpreaderModel {
  SPREADER_CPS_CIRRUS = 'CPS_CIRRUS',
  SPREADER_CPS_FREEDOM = 'CPS_FREEDOM',
  SPREADER_FORCE_AMERICA_GENERIC = 'FORCE_AMERICA_GENERIC'
}
