import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CartegraphConfiguration,
  CartegraphFeature,
  CartegraphFeatureFlag
} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-cartegraph-settings-features',
  templateUrl: './cartegraph-settings-features.component.html',
  styleUrls: ['../cartegraph-config/cartegraph-settings/cartegraph-settings.scss'],
})
export class CartegraphSettingsFeatures implements OnInit {

  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();

  @Input()
  configuration: CartegraphConfiguration;
  featureFlags: CartegraphFeatureFlag[];

  features: FeatureSelection[] = [
    {
      feature: CartegraphFeature.SEND_SHIFT,
      note: 'Send shift. Create completed shift tasks.',
      details: 'Submits shift details from PlowOps into Cartegraph. Depends on shift details configuration what fields are setup to what value.'
    },
    {
      feature: CartegraphFeature.SEND_PRE_INSPECTION,
      note: 'Send pre-inspection. Create task from pre-inspection.',
      details: 'Submits pre-inspection form as a Cartegraph task with set defaults activity, priority and status.'
    },
    {
      feature: CartegraphFeature.SEND_POST_INSPECTION,
      note: 'Send post-inspection. Create task from post-inspection.',
      details: 'Submits post-inspection form as a Cartegraph task with set defaults activity, priority and status.'
    },
    {
      feature: CartegraphFeature.SEND_OBSERVATION,
      note: 'Send observation. Create task from observation.',
      details: 'Submits observations as tasks with using mapping of observation type to a specified activity.'
    },
    {
      feature: CartegraphFeature.SEND_EQUIPMENT_LOG,
      note: 'Send equipment log. Send equipment log with the shift task.',
      details: 'When an equipment (vehicle attachment) is used, if it\'s log should be added to the shift task'
    },
    {
      feature: CartegraphFeature.SEND_MATERIAL_LOG,
      note: 'Send material log. Send material log with the shift task.',
      details: 'When a material is used, if it\'s log should be added to the shift task',
      childFeatures: [{
        feature: CartegraphFeature.MANUAL_MATERIAL_LOG,
        note: 'Send manually entered material values.',
        details: 'When sending material log, use values manually entered by driver.',
      }],
    },
    {
      feature: CartegraphFeature.IMPORT_INSPECTION_QUESTIONS,
      note: 'Import inspection questions. Import inspection questions from cgTaskClass layout.',
      details: 'Enables import of a specified form layout of cgTaskClass as inspection questions for pre/post inspections.'
    },
    {
      feature: CartegraphFeature.IMPORT_WORK_ORDERS,
      note: 'Import work orders. Import filtered cgWorkOrdersClass for work order selection.',
      details: 'Enables to import work orders and then use it as answer with mapping for inspection question.'
    },
    {
      feature: CartegraphFeature.USE_MATERIAL_MATCHING,
      note: 'Use custom Cartegraph fields to narrow filtering of materials with equipment in the app',
      details: 'When enabled user can configure list of fields to use for the material/equipment matching.'
    },
    {
      feature: CartegraphFeature.USE_CUSTOM_TASK_ID,
      note: 'Use custom TaskId auto-numbering',
      details: 'When on, PlowOps will generate own TaskId number with prefix, TaskId eg: PO-1, PO-2,...'
    }
  ];

  uiError: string;
  isLoading: boolean;

  constructor(private cartegraphManagementService: CartegraphManagementService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initFeatureFlags();
    this.isLoading = false;
  }

  initFeatureFlags() {
    this.featureFlags = this.configuration?.settings?.features ?? [];
    const children = this.features?.map(m => m.childFeatures).filter(f => !!f).flat();
    this.featureFlags.forEach(flag => {
      const feature = this.features?.find(f => f.feature === flag.feature);
      if (!!feature) {
        feature.checked = flag.enabled;
      }
      const childFeature = children?.find(c => c.feature === flag.feature);
      if (!!childFeature) {
        childFeature.checked = flag.enabled;
      }
    });
  }

  changeFeature(feature: FeatureSelection, checked: boolean) {
    // console.log('feature change', {feature, checked});
    const featureFlagIndex = this.featureFlags.findIndex(f => f.feature === feature.feature);
    if (featureFlagIndex >= 0) {
      this.featureFlags[featureFlagIndex].enabled = checked;
      if (feature.childFeatures?.length > 0) {
        feature.childFeatures.forEach(childFeature => {
          const childFlagIndex = this.featureFlags.findIndex(f => f.feature === childFeature.feature);
          if (childFlagIndex >= 0) {
            this.featureFlags[childFlagIndex].enabled = checked;
            childFeature.checked = childFeature.checked && checked;
          }
        });
      }
    } else {
      const flag = new CartegraphFeatureFlag();
      flag.feature = feature.feature;
      flag.enabled = checked;
      this.featureFlags.push(flag);
    }
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }


  updateFeatures() {
    this.uiError = null;
    this.isLoading = true;
    this.cartegraphManagementService.updateFeatures(this.featureFlags).then(response => {
      if (!!response.error) {
        this.uiError = response.error;
        this.showSnackBar('Failed to update features.');
      } else {
        this.configuration = response.data;
        this.initFeatureFlags();
        this.configurationChange.emit(response.data);
      }
      this.showSnackBar('Configuration successfully updated');
    }).catch(error => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });
  }

}

export class FeatureSelection {
  feature: CartegraphFeature;
  checked?: boolean;
  note: string;
  details?: string;
  childFeatures?: FeatureSelection[];
}
