import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {VehicleModel} from '../../../../../shared/models/vehicle.model';
import {
    DialogConfirmDeleteVehicleComponent, DialogMoveVehicleComponent,
    DialogUpdateVehicleComponent,
} from '../dialogs-vehicle/vehicle-dialog-components';
import {MatDialog} from '@angular/material/dialog';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {VehicleGroupModelWithVehicles} from '../model/VehicleGroupModelWithVehicles';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ToastService} from '../../../../../shared/services/toast.service';

@Component({
  selector: 'app-vehicle-group',
  templateUrl: './vehicle-group.component.html',
  styleUrls: ['./vehicle-group.component.scss']
})
export class VehicleGroupComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() configuration: ConfigurationModel;
  @Input() vehicleGroup: VehicleGroupModelWithVehicles;
  @Input() vehicles: VehicleModel[];

  @Output() editVehicleGroup: EventEmitter<any> = new EventEmitter();
  @Output() deleteVehicleGroup: EventEmitter<any> = new EventEmitter();
  @Output() vehicleDeletedEvent: EventEmitter<void> = new EventEmitter();
  @Output() vehicleMovedEvent: EventEmitter<VehicleModel> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<VehicleModel>;

  displayedColumns: string[] = [
    'name', 'label', 'licensePlate', 'mapColor', 'hasNoTablet', 'useCamera', 'hardwareConfiguration', 'lmuId', 'action'
  ];
  uiError: string;
  isImported = true;

  constructor(public dialog: MatDialog,
              private toast: ToastService,
              private vehicleService: VehiclesService,
              private configurationService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.vehicleGroup.vehicles);
    this.isImported = this.configurationService
      .hasFeatureFlag(this.configuration?.featureFlags, FeatureFlagEnum.CartegraphIntegration);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.vehicleGroup.vehicles);
    this.dataSource.sort = this.sort;
  }

  deleteVehicle(it: VehicleModel) {
    const dialogRef = this.dialog.open(DialogConfirmDeleteVehicleComponent, {
      width: '450px',
      data: it
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined && dialogResult) {
        this.vehicleService.deleteVehicle(dialogResult).toPromise()
            .then(() => {
              const foundIndex = this.vehicleGroup.vehicles.findIndex(value => value.id === it.id);
              this.vehicleGroup.vehicles.splice(foundIndex, 1);
              this.vehicleGroup.vehicles = [...this.vehicleGroup.vehicles]; // update mat-table datasource
              this.toast.short('Vehicle deleted.');
              this.uiError = '';
              this.vehicleDeletedEvent.emit();
            }).catch(error => {
              this.uiError = error.error;
            });
      }
    });
  }

  updateVehicle(it: VehicleModel) {
    const foundIndex = this.vehicleGroup.vehicles.findIndex(value => value.id === it.id);
    const vehicleToUpdate = JSON.parse(JSON.stringify(this.vehicleGroup.vehicles[foundIndex]));
    const dialogRef = this.dialog.open(DialogUpdateVehicleComponent, {
      width: '550px',
      data: {
        model: vehicleToUpdate,
        configuration: this.configuration,
        vehicles: this.vehicles,
        isImported: this.isImported,
        defaultMapColor: this.configurationService.trackStyles.liveMapPlowLive.color
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined && dialogResult) {
          dialogResult.hasNoTablet = !dialogResult.tablet;
          this.vehicleService.updateVehicle(vehicleToUpdate.id, dialogResult)
            .then(response => {
              const updatedVehicle = response.data;
              const toUpdate = this.vehicleGroup.vehicles[foundIndex];
              toUpdate.licensePlate = updatedVehicle.licensePlate;
              toUpdate.lmuId = updatedVehicle.lmuId;
              toUpdate.label = updatedVehicle.label;
              toUpdate.name = updatedVehicle.name;
              toUpdate.hardwareConfiguration = updatedVehicle.hardwareConfiguration;
              toUpdate.hasNoTablet = updatedVehicle.hasNoTablet;
              toUpdate.mapColor = updatedVehicle.mapColor;
              toUpdate.cameraConfiguration = updatedVehicle.cameraConfiguration;
              toUpdate.active = updatedVehicle.active;
              toUpdate.reportingType = updatedVehicle.reportingType;
              toUpdate.properties = updatedVehicle.properties;
              this.toast.short('Vehicle updated.');
              this.uiError = '';
            }).catch(error => {
              this.uiError = error;
              this.toast.long(error);
            });
      }
    });
  }

  moveVehicle(it: VehicleModel) {
      const foundIndex = this.vehicleGroup.vehicles.findIndex(value => value.id === it.id);
      const vehicleToUpdate = JSON.parse(JSON.stringify(this.vehicleGroup.vehicles[foundIndex]));
      const dialogRef = this.dialog.open(DialogMoveVehicleComponent, {
          width: '450px',
          data: it
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult !== undefined && dialogResult) {
              vehicleToUpdate.groupId = dialogResult;
              this.vehicleService.updateVehicle(vehicleToUpdate.id, vehicleToUpdate).then(response => {
                  this.vehicleMovedEvent.emit(response.data);
                  this.toast.short('Vehicle moved.');
                  this.uiError = '';
              }).catch(error => {
                  this.uiError = error;
                  this.toast.long(error);
              });
          }
      });
  }
}
