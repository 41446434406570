<div class="filter-selector-wrapper" @filterSelectorFadeDown>

  <!-- active layers buttons -->
  <div class="active-layers" *ngIf="!filterOpen && showLocationHistory">
    <ng-container *ngFor="let customLayer of customMapLayers">
      <div class="active-layer mat-elevation-z2" *ngIf="customLayer.checked">
        <span>{{ customLayer.name }}</span>
      </div>
    </ng-container>
  </div>

  <!-- main layers button -->
  <div
    *ngIf="!filterOpen"
    (click)="toggleVisibility()"
    class="layers__button noselect mat-elevation-z2"
    [ngStyle]="baseMapTypeBackground(BaseMapType.LIGHT)"
  >
    <mat-icon>layers</mat-icon>
    <span>Layers</span>
  </div>


  <!-- configuration box -->
  <div class="layers__box mat-elevation-z2" *ngIf="filterOpen">


    <!-- custom layers section -->
    <div class="layers__section" *ngIf="showConfiguration < 0">
      <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>My Layers</span>
        <div class="title__suffix"></div>
      </div>

      <div class="layers__buttons">
        <ng-container *ngFor="let customLayer of customMapLayers">
          <div class="map__filter__wrapper">
            <div class="map__filter__square noselect no__background"
                 [ngClass]="{ selected: customLayer.checked }"
                 (click)="toggleCustomMapLayer(customLayer, !customLayer.checked)"
            >
              <mat-icon>layers</mat-icon>
            </div>
            <div class="map__filter__text">
              {{ customLayer.name }}
            </div>
          </div>
        </ng-container>

        <div class="map__filter__wrapper" *ngIf="!!isAdmin">
          <div class="map__filter__square noselect configure"
               (click)="configure(ConfigurationType.CUSTOM_LAYERS)"
          >
            <mat-icon>settings</mat-icon>
          </div>
          <div class="map__filter__text">
            Configure
          </div>
        </div>
      </div>
    </div>


    <!-- weather section -->
    <div class="layers__section" *ngIf="!!configuration && (hasFeatureFlag(FeatureFlagEnum.Weather) || hasFeatureFlag(FeatureFlagEnum.Traffic)) && showConfiguration < 0">
      <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span *ngIf="hasFeatureFlag(FeatureFlagEnum.Weather) && hasFeatureFlag(FeatureFlagEnum.Traffic)">Weather and Traffic</span>
        <span *ngIf="hasFeatureFlag(FeatureFlagEnum.Weather) && !hasFeatureFlag(FeatureFlagEnum.Traffic)">Weather</span>
        <span *ngIf="!hasFeatureFlag(FeatureFlagEnum.Weather) && hasFeatureFlag(FeatureFlagEnum.Traffic)">Traffic</span>
        <div class="title__suffix"></div>
      </div>
      <div class="layers__buttons">
        <div class="map__filter__wrapper" *ngIf="hasFeatureFlag(FeatureFlagEnum.Weather)">
          <div [ngClass]="{ selected: showRadar }"
               [ngStyle]="weatherLayerToggleBackground(WeatherLayerType.RADAR)"
               class="map__filter__square noselect"
               (click)="toggleRadar()"
          ></div>
          <div class="map__filter__text">
            Weather
          </div>
        </div>

        <div class="map__filter__wrapper" *ngIf="hasFeatureFlag(FeatureFlagEnum.Traffic)">
          <div [ngClass]="{ selected: showTraffic }"
               [ngStyle]="weatherLayerToggleBackground(WeatherLayerType.TRAFFIC)"
               class="map__filter__square noselect"
               (click)="toggleTrafficLayer()"
          ></div>
          <div class="map__filter__text">
            Traffic
          </div>
        </div>
      </div>
    </div>


    <!-- activity filters section -->
    <div class="layers__section" *ngIf="showConfiguration < 0 && showLocationHistory">
      <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>Activity Filters</span>
        <div class="title__suffix"></div>
      </div>
      <div class="layers__buttons">

        <ng-container *ngFor="let activityFilter of activityFilters">
          <div class="map__filter__wrapper" *ngIf="activityFilter.visible">
            <div class="map__filter__square noselect no__background"
                 [ngClass]="{ selected: tracksFilter === activityFilter.type, mirrored: activityFilter.iconMirrored }"
                 (click)="setTrackFilter(activityFilter.type)"
            >
              <mat-icon>{{ activityFilter.icon }}</mat-icon>
            </div>
            <div class="map__filter__text">
              {{ activityFilter.label }}
            </div>
          </div>
        </ng-container>

        <div class="map__filter__wrapper">
          <div class="map__filter__square noselect configure"
               (click)="configure(ConfigurationType.ACTIVITIES)"
          >
            <mat-icon>add</mat-icon>
          </div>
          <div class="map__filter__text">
            Add Activity
          </div>
        </div>
      </div>
    </div>


    <!-- location history section -->
    <div class="layers__section" *ngIf="showConfiguration < 0 && showLocationHistory && !isAssetDetailRoute">
      <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>Location History</span>
        <div class="title__suffix"></div>
      </div>
      <div class="layers__buttons">

        <ng-container *ngFor="let tracksLayer of tracksLayers">
          <div class="map__filter__wrapper">
            <div class="map__filter__square noselect"
                 [ngClass]="{ selected: tracksLayer.type === tracksLayerType }"
                 [ngStyle]="locationLayerButtonBackground(tracksLayer.type)"
                 (click)="setTracksLayerType(tracksLayer.type)"
            ></div>
            <div class="map__filter__text">
              {{ tracksLayer.label }}
            </div>
          </div>
        </ng-container>

        <div class="map__filter__wrapper">
          <div class="map__filter__square noselect configure"
               (click)="configure(ConfigurationType.LOCATION_HISTORY)"
          >
            <mat-icon>settings</mat-icon>
          </div>
          <div class="map__filter__text">
            Configure
          </div>
        </div>
      </div>
    </div>


    <!-- basemap section -->
    <div class="layers__section" *ngIf="showConfiguration < 0">
      <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>Map</span>
        <div class="title__suffix"></div>
      </div>
      <div class="layers__buttons">

        <ng-container *ngFor="let baseMap of baseMaps">
          <div class="map__filter__wrapper">
            <div
                    [ngClass]="{ selected: baseMapLayerType === baseMap }"
                    class="map__filter__square noselect"
                    [ngStyle]="baseMapTypeBackground(baseMap)"
                    (click)="switchBaseMap(baseMap)"
            ></div>
            <div class="map__filter__text">
              {{ baseMap }}
            </div>
          </div>
        </ng-container>

      </div>
    </div>


    <!-- configurations below -->

    <!-- location history -->
    <div class="layers__section" *ngIf="showConfiguration === ConfigurationType.LOCATION_HISTORY">
      <div class="layers__header__wrapper">
        <div class="header__title">
          <span>Configure Location History</span>
        </div>
        <div class="header__action">
          <button mat-icon-button (click)="closeConfiguration()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <app-location-history-layers-config
              class="configuration__content"
              [configuration]="configuration"
      >
      </app-location-history-layers-config>
    </div>

    <!-- activities -->
    <div class="layers__section" *ngIf="showConfiguration === ConfigurationType.ACTIVITIES">
      <div class="layers__header__wrapper">
        <div class="header__title">
          <span>Add Activity</span>
        </div>
        <div class="header__action">
          <button mat-icon-button (click)="closeConfiguration()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <div class="layers__buttons">

        <ng-container *ngFor="let activityFilter of activityFilters">
          <div class="map__filter__wrapper">
            <div class="map__filter__square noselect no__background"
                 [ngClass]="{ selected: activityFilter.visible, mirrored: activityFilter.iconMirrored }"
                 (click)="toggleTrackFilterVisibility(activityFilter)"
            >
              <mat-icon>{{ activityFilter.icon }}</mat-icon>
            </div>
            <div class="map__filter__text">
              {{ activityFilter.label }}
            </div>
          </div>
        </ng-container>

      </div>
    </div>

    <!-- custom layers -->
    <div class="layers__section" *ngIf="showConfiguration === ConfigurationType.CUSTOM_LAYERS">
      <app-custom-layers-config
              [configuration]="configuration"
              (onClose)="closeConfiguration()"
              class="w-100"
      >
      </app-custom-layers-config>
    </div>
  </div>


  <!-- legends -->
  <ng-container>
    <!-- show route status legend all the time on Live Map Routes -->
    <div class="legend mat-elevation-z2" *ngIf="isRoutesRoute && isRouteStatusMode">
      <app-route-status-mode-legend
      >
      </app-route-status-mode-legend>
    </div>

    <div class="legend mat-elevation-z2" *ngIf="showLocationHistory && tracksLayerType === TracksLayerType.GPS_TRACKS">
      <app-gps-tracks-legend
              (settings)="filterOpen = true; configure(ConfigurationType.LOCATION_HISTORY)"
              (clearFilterEvent)="setTrackFilter(TracksLayerFilter.NONE)"
              [appliedFilter]="activeTracksFilter"
      >
      </app-gps-tracks-legend>
    </div>

    <div class="legend mat-elevation-z2" *ngIf="showLocationHistory && !isAssetDetailRoute && tracksLayerType === TracksLayerType.CURRENCY">
      <app-currency-legend
              (settings)="filterOpen = true; configure(ConfigurationType.LOCATION_HISTORY)"
              (clearFilterEvent)="setTrackFilter(TracksLayerFilter.NONE)"
              [appliedFilter]="activeTracksFilter"
      >
      </app-currency-legend>
    </div>

    <div class="legend mat-elevation-z2" *ngIf="showLocationHistory && !isAssetDetailRoute && tracksLayerType === TracksLayerType.COVERAGE">
      <app-coverage-legend
              (settings)="filterOpen = true; configure(ConfigurationType.LOCATION_HISTORY)"
              (clearFilterEvent)="setTrackFilter(TracksLayerFilter.NONE)"
              [appliedFilter]="activeTracksFilter"
      >
      </app-coverage-legend>
    </div>

    <div class="legend mat-elevation-z2" *ngIf="showTraffic">
      <app-traffic-legend
      >
      </app-traffic-legend>
    </div>

    <div class="legend mat-elevation-z2" *ngIf="showRadar || showWarnings">
      <app-weather-legend
      >
      </app-weather-legend>
    </div>
  </ng-container>
</div>
